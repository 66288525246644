import React from 'react'
import styled from 'styled-components'

import Audience from './Audience'
import Awareness from './Awareness'
import Hero from './Hero'
import Usability from './Usability'

const StyledIndex = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Index = () => {
  console.log('debug server')
  return (
    <StyledIndex>
      <Hero />
      <Awareness />
      <Usability />
      <Audience />
    </StyledIndex>
  )
}

export default Index
